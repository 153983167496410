import { lazy } from "react";

const AddDriver = lazy(() => import("pages/AddDriver"));
const AddVehicle = lazy(() => import("pages/AddVehicle"));
const AddCompany = lazy(() => import("pages/AddCompany"));
const AddProtocol = lazy(() => import("pages/AddProtocol"));
const AddShift = lazy(() => import("pages/AddShift"));
// const SendPushNotification = lazy(() => import("pages/SendPushNotification"));

const DriverDetails = lazy(() => import("pages/DriverDetails"));
const VehicleDetails = lazy(() => import("pages/VehicleDetails"));
const CompanyDetails = lazy(() => import("pages/CompanyDetails"));
const Partners = lazy(() => import("pages/Partners"));

const Home = lazy(() => import("pages/Home"));
const OwnCompany = lazy(() => import("pages/OwnCompany"));
const Shifts = lazy(() => import("pages/Shifts"));
const DriverShift = lazy(() => import("pages/DriverShift"));

const Login = lazy(() => import("pages/Login"));
const Drivers = lazy(() => import("pages/Drivers"));
const Vehicles = lazy(() => import("pages/Vehicles"));
const Companies = lazy(() => import("pages/Companies"));
const Protocols = lazy(() => import("pages/Protocols"));
const Notifications = lazy(() => import("pages/Notifications"));

const ShiftDetails = lazy(() => import("pages/ShiftDetails"));
const UserProfile = lazy(() => import("pages/UserProfile"));

const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const UberOrders = lazy(() => import("pages/UberOrders"));
const Revenue = lazy(() => import("pages/Revenue"));
const Expenses = lazy(() => import("pages/Expenses"));
export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const conditionalRoutes: IRoute[] = [
  { path: "/expenses", element: <Expenses /> },
];
export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
  { path: "forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
  { path: "/home", element: <Home /> },
  { path: "/orders", element: <UberOrders /> },
  { path: "/partners", element: <Partners /> },

  { path: "/shift", element: <DriverShift /> },
  { path: "/shift-details/:id", element: <ShiftDetails /> },

  { path: "/protocol", element: <AddProtocol /> },
  { path: "/profile", element: <UserProfile /> },
  { path: "/revenues", element: <Revenue /> },

  // { path: "/driver-shift", element: <DriverShift /> },

  // { path: "/driver-protocol", element: <AddProtocol /> },

  { path: "/drivers", element: <Drivers /> },
  { path: "/driver-details/:id", element: <DriverDetails /> },
  { path: "/add-driver", element: <AddDriver /> },
  { path: "/update-driver/:id", element: <AddDriver /> },

  { path: "/vehicles", element: <Vehicles /> },
  { path: "/add-vehicle", element: <AddVehicle /> },
  { path: "/update-vehicle/:id", element: <AddVehicle /> },
  { path: "/vehicle-details/:id", element: <VehicleDetails /> },
];
export const admin_private_routes: IRoute[] = [
  { path: "/own-company", element: <OwnCompany /> },

  { path: "/protocols", element: <Protocols /> },
  { path: "/protocol-details/:id", element: <AddProtocol /> },

  { path: "/notifications", element: <Notifications /> },
  // { path: "/send-notification", element: <SendPushNotification /> },

  { path: "/shifts", element: <Shifts /> },
  { path: "/add-shift", element: <AddShift /> },
  { path: "/add-shift/:id", element: <AddShift /> },
];

export const globalAdmin_private_routes: IRoute[] = [
  { path: "/companies", element: <Companies /> },
  { path: "/add-company", element: <AddCompany /> },
  { path: "/companies-details/:id", element: <CompanyDetails /> },
];
