import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CampaignIcon from "@mui/icons-material/Campaign";
import { SiSpringCreators } from "react-icons/si";
import { useLocation, useNavigate } from "react-router-dom";
import { FaBuilding } from "react-icons/fa6";
import { useAppSelector } from "redux/hooks";
import BasicMenu from "components/atoms/BasicMenu";
import AuthService from "services/auth.service";
import { SiHandshakeProtocol } from "react-icons/si";
import { IoIosNotifications } from "react-icons/io";
import { FaCarAlt } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaUber } from "react-icons/fa6";
import { BsBuildingsFill } from "react-icons/bs";
import { GiProfit } from "react-icons/gi";
import { GiExpense } from "react-icons/gi";
import { MdOutlineRepartition } from "react-icons/md";

import Logo from "assets/long_logo.png";
import { Container } from "@mui/material";
import UserService from "services/user.service";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useEffectOnce from "hooks/useEffectOnce";
import OneSignalService from "services/onesignal.service";
import { ImProfile } from "react-icons/im";
import { IoLogOutSharp } from "react-icons/io5";

let languages: any = { en: "EN", de: "DE", tr: "TR", ar: "AR" };
export const userMenu = (user: any) => {
  const list = [
    {
      title: "documentShift",
      link: "/shift",
      icon: <CampaignIcon />,
    },
    {
      title: "protocol",
      link: "/protocol",
      icon: <SiHandshakeProtocol />,
    },
    {
      title: "orders",
      link: "/orders",
      icon: <FaUber />,
    },
    {
      link: "/revenues",
      title: "Revenues",
      icon: <FaFileInvoiceDollar />,
    },
  ];
  if (["admin", "company"].includes(user.role) || user.addExpense) {
    list.push({
      link: "/expenses",
      title: "expenses",
      icon: <GiExpense />,
    });
  }
  return list;
};

const profile = [
  {
    link: "/profile",
    title: "Profile",
    icon: <ImProfile />,
  },
  {
    to: "/logout",
    title: "Logout",
    icon: <IoLogOutSharp />,
  },
];

const adminMenu = [
  {
    to: "/shifts",
    text: "shifts",
    active: "/shifts",
    icon: <CampaignIcon />,
  },
  {
    to: "/drivers",
    text: "drivers",
    active: "driver",
    icon: <FaUserTie />,
  },

  {
    to: "/vehicles",
    text: "vehicles",
    active: "vehicle",
    icon: <FaCarAlt />,
  },
  {
    to: "/protocols",
    text: "protocols",
    active: "protocols",
    icon: <SiHandshakeProtocol />,
  },
  {
    to: "/notifications",
    text: "Notifications",
    active: "notifications",
    icon: <IoIosNotifications />,
  },
  // {
  //   to: "/revenue",
  //   text: "Revenue",
  //   active: "revenue",
  //   icon: <GiProfit />,
  // },
  {
    to: "/own-company?tab=0",
    text: "ownCompany",
    active: "own",
    icon: <FaBuilding />,
  },
  {
    to: "/partners",
    text: "Partners",
    active: "partner",
    icon: <MdOutlineRepartition />,
  },
];
const drawerWidth = 272;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function MiniDrawer({
  children,
}: {
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const once = React.useRef(false);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.auth.user);
  const driverShift = useAppSelector((state) => state.shift.driverShift);
  // useEffectOnce(() => {
  //   if (!user) return;
  //   console.log("user", user);
  //   OneSignalService.connect(user._id);
  // });
  React.useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user.language);
      moment.locale(user.language);
    }
    if (!once.current && user) {
      OneSignalService.connect(user._id, user?.uberIds);
      once.current = false;
    }
  }, [user, i18n]);
  if (!user || pathname === "/") return <>{children}</>;

  return (
    <Container maxWidth={"xl"}>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          height: "100vh",
          overflow: "hidden",
          // maxWidth: "1500px",
          // margin: "auto",
        }}
      >
        <Box
          sx={{
            height: "100vh",
            overflow: "hidden",
            width: `${drawerWidth}px`,
            p: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              px: 2,
              borderRadius: "6px",
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "space-between",

              height: "64px",
              boxShadow: "rgba(0, 0, 0, 0.05) 0rem 1.25rem 3rem 0rem",
            }}
          >
            <div>
              <img
                src={Logo}
                style={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "40px",
                  maxWidth: "80px",
                }}
                alt=""
              />{" "}
            </div>

            <BasicMenu
              variant="contained"
              sx={{
                padding: "10px",
                "&:hover": {
                  color: "white",
                },
              }}
              list={[
                {
                  text: "EN",
                  onClick: () => {
                    UserService.updateProfile({ language: "en" });
                  },
                },
                {
                  text: "DE",
                  onClick: () => {
                    UserService.updateProfile({ language: "de" });
                  },
                },
                {
                  text: "TR",
                  onClick: () => {
                    UserService.updateProfile({ language: "tr" });
                  },
                },
                {
                  text: "AR",
                  onClick: () => {
                    UserService.updateProfile({ language: "ar" });
                  },
                },
              ]}
            >
              {languages[user.language]}
            </BasicMenu>
          </Box>
          <div style={{ height: "calc(100% - 64px)", overflow: "auto" }}>
            <h3 style={{ paddingLeft: "20px" }}>{t("Driver")}</h3>
            <List>
              {userMenu(user)?.map((item: any, index: number) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    marginBottom: "8px",
                    fontSize: "16px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      alignItems: "center",
                      px: 2.5,
                      borderRadius: "6px",
                      background:
                        pathname === item.link
                          ? theme.palette.primary.main
                          : "white",
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
                      color: pathname === item.link ? "white" : "black",
                      "&:hover": {
                        color: "black",
                      },
                    }}
                    onClick={() => navigate(item.link)}
                  >
                    <div
                      style={{
                        width: "40px",
                        marginTop: "6px",
                      }}
                    >
                      {item.icon}
                    </div>
                    <ListItemText primary={t(item.title)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            {["company", "admin"].includes(user?.role) && (
              <>
                <h3 style={{ paddingLeft: "20px" }}>{t("administration")}</h3>
                <List>
                  {adminMenu?.map((item: any, index: number) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "16px",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          borderRadius: "6px",

                          justifyContent: "center",
                          alignItems: "center",
                          px: 2.5,
                          background: pathname.includes(item.active)
                            ? theme.palette.primary.main
                            : "white",
                          boxShadow:
                            "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
                          color: pathname.includes(item.active)
                            ? "white"
                            : "black",
                          "&:hover": {
                            color: "black",
                          },
                        }}
                        onClick={() => navigate(item.to)}
                      >
                        <div
                          style={{
                            width: "40px",
                            marginTop: "6px",
                          }}
                        >
                          {item.icon}
                        </div>
                        <ListItemText primary={t(item.text)} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                  {user?.role == "admin" && (
                    <ListItem
                      disablePadding
                      sx={{
                        display: "block",
                        marginBottom: "8px",
                        fontSize: "16px",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          borderRadius: "6px",

                          justifyContent: "center",
                          alignItems: "center",
                          px: 2.5,
                          background:
                            pathname.includes("companies") &&
                            !pathname.includes("own-company")
                              ? theme.palette.primary.main
                              : "white",
                          boxShadow:
                            "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
                          color:
                            pathname.includes("companies") &&
                            !pathname.includes("own-company")
                              ? "white"
                              : "black",
                          "&:hover": {
                            color: "black",
                          },
                        }}
                        onClick={() => navigate("/companies")}
                      >
                        <div
                          style={{
                            width: "40px",
                            marginTop: "6px",
                          }}
                        >
                          <BsBuildingsFill />
                        </div>
                        <ListItemText primary={t("companies")} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </>
            )}
            <List>
              {profile?.map((item: any, index: number) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    marginBottom: "8px",
                    fontSize: "16px",
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      alignItems: "center",
                      px: 2.5,
                      borderRadius: "6px",
                      background:
                        pathname === item.link
                          ? theme.palette.primary.main
                          : "white",
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem",
                      color: pathname === item.link ? "white" : "black",
                      "&:hover": {
                        color: "black",
                      },
                    }}
                    onClick={() => {
                      if (item.title == "Logout") {
                        AuthService.logout();
                      } else {
                        navigate(item.link);
                      }
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        marginTop: "6px",
                      }}
                    >
                      {item.icon}
                    </div>
                    <ListItemText primary={t(item.title)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        </Box>
        <Box
          component="main"
          sx={{ flex: 1, pl: 3, width: `calc(100% - ${drawerWidth}px)` }}
        >
          <Box
            sx={{ flex: 1, pr: 3, pt: 1, overflowY: "auto", height: "100vh" }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
